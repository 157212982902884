import React, { useState, useEffect } from "react";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Container, Row, Spinner, Table } from "react-bootstrap";
import PopUp from "../Layouts/popUp";
import ItemDataTable from "../Layouts/itemDataTable";
import axios from "axios";
import Swal from "sweetalert2";
import FormGroup from "../Components/atoms/FormGroup";
import SelectGroup from "../Components/atoms/SelectGroup";

const defaultPricing = {
    id: 0,
    currency:0,
    price:0,
    unit:0
};
const defaultInventories = {
    id: 0,
    location: 0,
    qty: 0,
    unit: 0
}
const defaultDiscount = {
    id: 0,
    currency: 0,
    percent: 0,
    amount: 0
}
const defaultProduct = {
    id: 0,
    code: '',
    description: '',
    subCategory: 0,
    inventory: false,
    pricing: [defaultPricing],
    taxes: [],
    inventories: [defaultInventories],
    discounts: defaultDiscount,
    image: null,
    isCosting: 'f',
    unit_des: "-"
}

const defaultGeneralData = {
    units: []
}

const TakeOutProduction = () => {
    const [showPopUp,setShowPopUp] = useState(false),
        [product,setProduct] = useState(defaultProduct),
        [,setExtraPricing] = useState([]),
        [showMaterialPopUp,setShowMaterialPopUp] = useState(false),
        [material,setMaterial] = useState(defaultProduct),
        [recipeDetails,setRecipeDetails] = useState([]),
        [isLoading,setIsLoading] = useState(false),
        [productQty,setProductQty] = useState(1),
        [productUnitId,setProductUnitId] = useState(1),
        [generalData,setGeneralData] = useState(defaultGeneralData)
    ;
    useEffect(async()=>{
        try{
            const resource = await axios.get(process.env.REACT_APP_PATH_API + 'get/miscellaneous/data'),
            details = resource.data;
            if(product!==undefined&&product!==null&&product.length>0){
                if(product[0].unit){
                    setProductUnitId(product[0].unit);
                }
            }
            setGeneralData(details);
        } catch(error){
            console.log(':::::ERROR:::::', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Error Interno, favor contacte al administrador"
            });
        }
    },[]);
    useEffect(async()=>{
        if(product.id>0)
        {
            try{
                const resource = await axios.get(process.env.REACT_APP_PATH_API + 'recipe/details/by/product/'+product.id),
                details = resource.data;
                setRecipeDetails(details);
            } catch(error){
                console.log(':::::ERROR:::::', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: "Error Interno, favor contacte al administrador"
                });
            }
        }
    },[product]);
    useEffect(()=>{
        const list = [...recipeDetails];
        if(productQty>0){            
            for(let contador=0;contador<list.length;contador++){
                list[contador].qty_unit = productQty * list[contador].default_qty_unit;         
            }
        }
        setRecipeDetails(list);
    },[productQty]);
    const setQtyMaterial = (input) => {
        const id = parseInt(input.target.id),
            value = parseFloat(input.target.value),
            list = [...recipeDetails];
        for(let contador=0;contador<list.length;contador++){
            if( parseInt(list[contador].item_id) === parseInt(id) ){
                list[contador].qty_unit = productQty * value;
                break;
            }            
        }
        setRecipeDetails(list);
    }
    const processTakeOutProduction = async() => {
        setIsLoading(true);
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'take/out/production',{product: product, productQty: productQty, productUnitId: productUnitId, recipeDetails: recipeDetails,token: localStorage.getItem('OSHEN-LOC-STR-TK')}),
            data = await resource.data;
            if(data.status){
                setIsLoading(false);
                Swal.fire({
                    icon: 'success',
                    title: 'Éxito',
                    text: data.msg
                }).then(()=>{
                    location.reload();
                });
            } else {
                setIsLoading(false);
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: data.msg
                });
            }
            setIsLoading(false);
        } catch(error){
            setIsLoading(false);
            Swal.fire({
                icon: 'error',
                title: 'Error interno',
                text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
            });
        }

    }
    const confirmTakeOutProduction = () => {
        Swal.fire({
            title: '¿Esta seguro de realizar esta salida de producción?',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
        }).then((result)=>{
            if (result.isConfirmed) {
                processTakeOutProduction();                
            }
        });
    }
    const setMaterialQty = (input) => {
        let value = input.target.value;
        setMaterial({
            ...material,
            qtyUnit: value
        })     
    }
    const setMaterialUnit = (input) => {
        let value = input.target.value,
            unitDes = "";
        for(let counter = 0; counter < generalData.units.length; counter++){
            const obj = generalData.units[counter];
            if(parseInt(obj.value)==parseInt(value)){
                unitDes = obj.description
            }
        }
        setMaterial({
            ...material,
            unitId: value,
            unitDes: unitDes
        })
    }
    const addToDispatchList = () => {
        let list = [...recipeDetails];
        if(list.length)
        {
            let isInTable = false;
            for(let contador=0;contador<list.length;contador++){
                if( parseInt(list[contador].item_id) === parseInt(material.id) ){
                    isInTable = true;
                    list[contador].qty_unit = productQty * material.qtyUnit;
                    list[contador].unit_id = material.unitId;
                    list[contador].unit_des = material.unitDes;
                    break;
                }            
            }
            if(isInTable===false){
                list.push({
                    item_id: material.id,
                    product_code: material.code,
                    product_des: material.description,
                    item_cost: 0,
                    qty_unit: productQty * material.qtyUnit,
                    default_qty_unit: material.qtyUnit,
                    unit_id: material.unitId,
                    unit_des: material.unitDes
                });
            }
        } else {
            list.push({
                item_id: material.id,
                product_code: material.code,
                product_des: material.description,
                item_cost: 0,
                qty_unit: productQty * material.qtyUnit,
                default_qty_unit: material.qtyUnit,
                unit_id: material.unitId,
                unit_des: material.unitDes
            });
        }
        setRecipeDetails(list);
        setMaterial(defaultProduct);
    }
    return (
    <>
        <Container fluid>
            <Row>
                <Col style={{padding:'0.5rem',paddingLeft:'2rem'}}>
                    <a 
                        // eslint-disable-next-line no-undef
                        href={process.env.REACT_APP_PATH_APP}
                        className="navbar-brand" 
                        style={{fontSize: '14px'}}
                        >
                        <FontAwesomeIcon icon={faArrowLeft} /> Retornar a la aplicación
                    </a>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <Card.Header>Salida De Producción</Card.Header>
                        <Card.Body>
                            <Card.Title>Selecciona un producto terminado para cargar de receta o crear el listado a despachar</Card.Title>
                            <Button variant="info" style={{fontSize: '14px',color: '#fff'}} onClick={()=>setShowPopUp(true)}>Buscar Producto Para Salida</Button>

                            {
                                product.id>0?
                                    <Table style={{marginTop: '20px'}} bordered>
                                        <thead className="bg-table-head-primary">
                                            <tr><th colSpan={4}>PRODUCTO SELECCIONADO</th></tr>
                                            <tr>
                                                <th style={{width: '20%'}}>CÓDIGO</th>
                                                <th style={{width: '30%'}}>DESCRIPCIÓN</th>
                                                <th style={{width: '25%'}}>CANTIDAD</th>
                                                <th style={{width: '25%'}}>UNIT</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{product.code}</td>
                                                <td>{product.description}</td>
                                                <td>
                                                    <FormGroup 
                                                        id={product.id.toString()}
                                                        onChange={(input)=>setProductQty(input.target.value)}
                                                        value={productQty}
                                                        className='col' 
                                                        inputType='number' 
                                                        label={<span>-</span>} 
                                                        inputName={`qtyTakeOut${product.id}`}
                                                    />
                                                </td>
                                                <td>
                                                <SelectGroup 
                                                    onChange={(input)=>setProductUnitId(input.target.value)}
                                                    className="col" 
                                                    label="Unidad"
                                                    inputName="unit" 
                                                    id="unit"
                                                    value={productUnitId}
                                                    options={generalData.units}
                                                />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                :null
                            }

                            <Card.Text className="text-muted">
                                Si un material tiene la cantidad a despachar en 0 no sera tomado en cuenta para dicho despacho...
                            </Card.Text>

                            {
                                product.id>0?
                                    <Button variant="info" style={{fontSize: '14px',color: '#fff'}} onClick={()=>setShowMaterialPopUp(true)}>Buscar Material Para Despacho</Button>
                                :null
                            }                            

                            {
                                material.id>0?
                                    <Table style={{marginTop: '20px'}} bordered>
                                        <thead className="bg-table-head-primary">
                                            <tr><th colSpan={4}>MATERIAL SELECCIONADO</th></tr>
                                            <tr>
                                                <th style={{width: '20%'}}>CÓDIGO</th>
                                                <th style={{width: '30%'}}>DESCRIPCIÓN</th>
                                                <th style={{width: '25%'}}>CANTIDAD</th>
                                                <th style={{width: '25%'}}>UNIT</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{material.code}</td>
                                                <td>{material.description}</td>
                                                <td>
                                                    <FormGroup 
                                                        id={material.id}
                                                        onChange={setMaterialQty}
                                                        value={material!==undefined&&material!==null&&material.qtyUnit!==undefined&&material.qtyUnit!==null?material.qtyUnit:0}
                                                        className='col' 
                                                        inputType='number' 
                                                        label={<span>-</span>} 
                                                        inputName={`qtyMaterialTakeOut${material.id}`}
                                                    />
                                                </td>
                                                <td>
                                                    <SelectGroup 
                                                        onChange={setMaterialUnit}
                                                        className="col" 
                                                        label="-"
                                                        inputName="unitMaterial" 
                                                        id="unitMaterial"
                                                        value={material!==undefined&&material!==null&&material.unitId!==undefined&&material.unitId!==null?material.unitId:1}
                                                        options={generalData.units}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th colSpan={4}>
                                                    <Button variant="success" onClick={addToDispatchList}>Agregar material a lista de despacho</Button>
                                                </th>
                                            </tr>
                                        </tfoot>
                                    </Table>
                                :null
                            }

                            {
                                recipeDetails.length?
                                    <Table style={{marginTop: '20px'}} bordered>
                                        <thead>
                                            <tr><th colSpan={4}>DETALLES EN RECETA DE PRODUCTO</th></tr>
                                            <tr>
                                                <th style={{width: '20%'}}>CÓDIGO</th>
                                                <th style={{width: '50%'}}>DESCRIPCIÓN</th>
                                                {/* <th style={{width: '20%'}}>COSTO</th> */}
                                                <th style={{width: '30%'}}>CANTIDAD A DESPACHAR</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                recipeDetails.map(({item_id,product_code,product_des,/*item_cost,*/qty_unit,unit_des},index)=>{
                                                    return (
                                                        <tr key={`recipe-details-${index}-${item_id}`}>
                                                            <td>{product_code}</td>
                                                            <td>{product_des}</td>
                                                            {/* <td>{item_cost}</td> */}
                                                            <td>
                                                                <FormGroup 
                                                                    id={item_id}
                                                                    onChange={setQtyMaterial}
                                                                    value={qty_unit}
                                                                    className='col' 
                                                                    inputType='number' 
                                                                    label={<span>Cantidad En <strong>{unit_des}</strong></span>} 
                                                                    inputName={`price${item_id}`}
                                                                />
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }                                            
                                        </tbody>
                                    </Table>
                                :null
                            }
                            <hr style={{borderColor: '#eee'}} />
                            {
                                isLoading?
                                    <Button variant="primary" disabled>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        Loading...
                                    </Button>
                                :<Button variant="primary" onClick={confirmTakeOutProduction}>Procesar Salida De Producción</Button>
                            }                            
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
        <PopUp 
            show={showPopUp}
            handle={setShowPopUp}
            content={<ItemDataTable 
                        handlePopUp={setShowPopUp} 
                        handleProduct={setProduct}
                        setExtraPricing={setExtraPricing}
                        isMaterial={false} />}
        />
        <PopUp 
            show={showMaterialPopUp}
            handle={setShowMaterialPopUp}
            content={<ItemDataTable 
                        handlePopUp={setShowMaterialPopUp} 
                        handleProduct={setMaterial}
                        setExtraPricing={setExtraPricing}
                        isMaterial={true} />}
        />
    </>
    )
}

export default TakeOutProduction;