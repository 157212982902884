import axios from "axios";
import React, {useState,useEffect} from "react";
import Swal from "sweetalert2";
import TaskContent from "./snippets/task-content";
import TaskForm from "./snippets/task-form";
import TaskList from "./snippets/task-list";
import UsrTask from "./snippets/usr-task";

const styling = {
    container: {
        backgroundColor: '#eee',
        height: '100vh'
    },
    columns: {
        backgroundColor: '#fff',
        height: '100vh',
        overflowY: 'auto',
        paddingLeft: '0px',
        paddingRight: '0px',
        borderRight: '1px solid #eee'
    }
}
const defaultInfo = {
    usr: {},
    tasks: [],
    priorities: [],
    departments: []
}
const Task = () => {
    const [taskModal,setTaskModal] = useState(false),
        [info,setInfo] = useState(defaultInfo),
        [taskInfo,setTaskInfo] = useState({}),
        [taskFA,setTaskFA] = useState([]),
        [lastTasks,setLastTasks] = useState([]),
        [taskOpenned,setTaskOpenned] = useState([]),
        [loadingTaskInfo,setLoadingTaskInfo] = useState(false),
        //view assigned
        [viewer,setViewer] = useState(1),
        [usrList,setUsrList] = useState([]),
        [allowApprove,setAllowApprove] = useState(false),
        [inputsPermission,setInputsPermission] = useState(false),
        [createPermission,setCreatePermission] = useState(false);
    useEffect(async()=>{
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.get(process.env.REACT_APP_PATH_API + 'tasks/'+localStorage.getItem('OSHEN-LOC-STR-TK')),
                data =  await resource.data;
                if(data.sessionOut){
                    // eslint-disable-next-line no-undef
                    location.href=process.env.REACT_APP_PATH_APP;
                    return;
                }
                setInfo({
                    ...info,
                    usr: data.usr,
                    tasks: data.tasks,
                    priorities: data.priorities,
                    departments: data.departments
                });
                setTaskFA(data.tasksFA);
                setTaskOpenned(data.tasksOP);
                setLastTasks(data.lastTasks);
                setInputsPermission(data.inputsPermission);
                setCreatePermission(data.createPermission)
        } catch(error){
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Error Interno, favor contacte al administrador"
            });
        }
    },[]);
    return (
        <>
        <div style={{width: '100%',height: '100%',backgroundColor: '#eee'}}>
            <div className="container-fluid" style={styling.container}>
                <div className="row">
                    <div className="col-3" style={styling.columns}>
                        <UsrTask
                            usr={info.usr}
                            taskFA={taskFA}
                            setTaskFA={setTaskFA}
                            taskOpenned={taskOpenned}
                            lastTasks={lastTasks}
                            setLastTasks={setLastTasks}
                            setTaskOpenned={setTaskOpenned}
                            setTaskInfo={setTaskInfo}
                            setUsrList={setUsrList}
                            setAllowApprove={setAllowApprove}
                            setLoadingTaskInfo={setLoadingTaskInfo}
                         />
                    </div>
                    <div className="col-3" style={styling.columns}>
                        <TaskList
                            fnTaskModal={setTaskModal}
                            tasks={info.tasks}
                            setterInfo={setInfo}
                            info={info}
                            setTaskInfo={setTaskInfo}
                            setLoadingTaskInfo={setLoadingTaskInfo}
                            setViewer={setViewer}
                            viewer={viewer}
                            setUsrList={setUsrList}
                            setAllowApprove={setAllowApprove}
                            createPermission={createPermission}
                         />
                    </div>
                    <div className="col-6" style={styling.columns}>
                        <TaskContent
                            taskInfo={taskInfo}
                            loadingTaskInfo={loadingTaskInfo}
                            usrList={usrList}
                            allowApprove={allowApprove}
                            setTaskInfo={setTaskInfo}
                            usr={info.usr}
                            viewer={viewer}
                            setInfo={setInfo}
                            info={info}
                            setTaskFA={setTaskFA}
                            setTaskOpenned={setTaskOpenned}
                            inputsPermission={inputsPermission}
                            setLastTasks={setLastTasks}
                         />
                    </div>
                </div>            
            </div>
            <TaskForm 
                show={taskModal}
                handle={setTaskModal}
                priorities={info.priorities}
                departments={info.departments}
                viewer={viewer}
                setInfo={setInfo}
                info={info}
                setLastTasks={setLastTasks}
            />
        </div>
        </>
    );
}

export default Task;