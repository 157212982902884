import React, {useState, useEffect, useRef} from "react";
import { faArrowLeft, faCheck, faCheckCircle, faClipboardCheck, faCopy, faPenAlt, faPlus, faPlusCircle, faTimes, faTimesRectangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Swal from "sweetalert2";
import Select from 'react-select';
import SelectGroup from "../../atoms/SelectGroup";
import {nFormat} from "../../../Vendor/common";
import FormGroup from "../../atoms/FormGroup";
import Button from "../../atoms/Button";
import { Spinner } from "react-bootstrap";

import { useSelector, useDispatch } from "react-redux";
import { /*add,*/ remove } from "../../../features/counter/quoteItemsSlice";
import { useNavigate } from "react-router";

const loadingDefault = {
    chargingProducts: false,
    chargingUpdatePrice: false,
    chargingRemoveRecipe: false,
    chargingCompleteRecipe: false,
    chargingTmpRecipe: false
}
const RecipeItemContentView = (props) => {

    // eslint-disable-next-line react/prop-types
    const { generalData, setGeneralDataRecipe, product } = props;

    const [itemList,setItemList] = useState([]),
        [unitConversions,setUnitConversions] = useState([]),
        [itemSelected,setItemSelected] = useState(null),
        [itemCost,setItemCost] = useState(''),
        [defaultItemCost,setDefaultItemCost] = useState(0),
        [itemCurrentUnit,setItemCurrentUnit] = useState(0),
        [itemQtyUnit,setItemQtyUnit] = useState(''),
        [itemUnit,setItemUnit] = useState(0),
        [subCategoryItem,setSubCategoryItem] = useState(0),
        // eslint-disable-next-line react/prop-types
        [deactivateCompleteBtn,setDeactivateCompleteBtn] = useState(false),

        [showCopyRecipeOption,setShowCopyRecipeOption] = useState(false),
        [fCopyRecipe,setFCopyRecipe] = useState(null),

        [loadings,setLoadings] = useState(loadingDefault),
        
        quoteItems = useSelector((state) => state.quoteItems.value),
        dispatch = useDispatch(),
        
        reactSelect = useRef(),
        
        navigate = useNavigate();

    let totalCost = 0,
        tCost = 0;
    useEffect(()=>{
        if(itemSelected){
            setItemCost(itemSelected.cost);
            setDefaultItemCost(itemSelected.cost);
            setItemCurrentUnit(itemSelected.unit_id);
        }
    },[itemSelected]);
    useEffect(()=>{
        // eslint-disable-next-line react/prop-types
        const deactivate = generalData.recipes.status_recipe=='COM'?true:false;
        setDeactivateCompleteBtn(deactivate);
    },[generalData]);
    const getItemsBySubCategory = async(input) => {
        const value = input.target.value;
        if(value){ 
            setLoadings({
                ...loadings,
                chargingProducts: true
            });
            try{
                // eslint-disable-next-line no-undef
                const resource = await axios.get(process.env.REACT_APP_PATH_API + 'get/items/by/subcategory/'+value),
                    data =  await resource.data;
                    setItemList(data.list);
                    setUnitConversions(data.unitConversions);
                    setSubCategoryItem(value);
                    setItemSelected(null);
                    //setItemCost(0);
                    setLoadings({
                        ...loadings,
                        chargingProducts: false
                    });
            } catch(error){
                setLoadings({
                    ...loadings,
                    chargingProducts: false
                });
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: "Error Interno, favor contacte al administrador"
                });
            }
        }
    }
    const addToRecipe = async() => {
        const validation = recipeAddItemValidation();
        if(validation.valid){
            Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: validation.msg
            });
            return;
        }
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'recipe/item',{
                token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                // eslint-disable-next-line react/prop-types
                recipeId: generalData.recipeId,
                itemId: itemSelected.value,
                itemCost: itemCost,
                qtyUnit: itemQtyUnit,
                unitId: itemUnit
            }),
            data =  await resource.data;
            if(data.sessionOut){
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: 'La sesión ha expirado, será redireccionado'
                }).then(()=>{
                    // eslint-disable-next-line no-undef
                    location.href = process.env.REACT_APP_PATH_APP;
                });
                return false;
            }
            if(!data.status){
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: data.msg
                });
                return;
            }
            // eslint-disable-next-line react/prop-types
            props.setRecipeItems(data.items);
            // eslint-disable-next-line react/prop-types
            props.setProduct(data.product);
            //clear inputs
            clearInputs();
        } catch(error){
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Error Interno, favor contacte al administrador"
            });
        }
    }
    const removeItem = async(id) => {
        if(id){
            try{
                // eslint-disable-next-line no-undef
                const resource = await axios.post(process.env.REACT_APP_PATH_API + 'recipe/remove',{
                    token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                    id: id
                }),
                data =  await resource.data;
                if(data.sessionOut){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Advertencia',
                        text: 'La sesión ha expirado, será redireccionado'
                    }).then(()=>{
                        // eslint-disable-next-line no-undef
                        location.href = process.env.REACT_APP_PATH_APP;
                    });
                    return false;
                }
                if(!data.status){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Advertencia',
                        text: data.msg
                    });
                    return;
                }
                // eslint-disable-next-line react/prop-types
                props.setRecipeItems(data.items);
                // eslint-disable-next-line react/prop-types
                props.setProduct(data.product);
            } catch(error){
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: "Error Interno, favor contacte al administrador"
                });
            }
        }
    }
    const complete = async() => {
        setLoadings({
            ...loadings,
            chargingCompleteRecipe: true
        });
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'recipe/completed',{
                token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                // eslint-disable-next-line react/prop-types
                id: generalData.recipeId
            }),
            data =  await resource.data;
            setLoadings({
                ...loadings,
                chargingCompleteRecipe: false
            });
            if(data.sessionOut){
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: 'La sesión ha expirado, será redireccionado'
                }).then(()=>{
                    // eslint-disable-next-line no-undef
                    location.href = process.env.REACT_APP_PATH_APP;
                });
                return false;
            }
            if(!data.status){
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: data.msg
                });
                return;
            }
            setDeactivateCompleteBtn(true);
        } catch(error){
            setLoadings({
                ...loadings,
                chargingCompleteRecipe: false
            });
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Error Interno, favor contacte al administrador"
            });
        }
    }
    const desMark = async() => {
        setLoadings({
            ...loadings,
            chargingTmpRecipe: true
        });
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'recipe/comeback/tmp',{
                token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                // eslint-disable-next-line react/prop-types
                id: generalData.recipeId
            }),
            data =  await resource.data;
            setLoadings({
                ...loadings,
                chargingTmpRecipe: false
            });
            if(data.sessionOut){
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: 'La sesión ha expirado, será redireccionado'
                }).then(()=>{
                    // eslint-disable-next-line no-undef
                    location.href = process.env.REACT_APP_PATH_APP;
                });
                return false;
            }
            if(!data.status){
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: data.msg
                });
                return;
            }
            setDeactivateCompleteBtn(false);
        } catch(error){
            setLoadings({
                ...loadings,
                chargingTmpRecipe: false
            });
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Error Interno, favor contacte al administrador"
            });
        }
    }
    // eslint-disable-next-line no-unused-vars
    const addProductToQuote = () => {
        // eslint-disable-next-line no-undef
        location.href = window.open(process.env.REACT_APP_PATH_APP + 'quotations?app=135', '_blank');
        // eslint-disable-next-line react/prop-types
        // dispatch(add({productId: parseInt(product.id)}));
    }
    const removeProductFromQuote = () => {
        // eslint-disable-next-line react/prop-types
        dispatch(remove({productId: parseInt(product.id)}))
    }
    const clearInputs = () => {
        setSubCategoryItem(0);
        setItemQtyUnit('');
        setItemUnit(0);
        setItemCost('');
        setItemList([]);
        setItemSelected(null);
    }
    const setterQty = (input) => {
        if(input.target.value.length){
            const value = parseFloat(input.target.value);
            if(value>=0){
                setItemQtyUnit(value);
                // if(itemUnit>0&&value>0&&itemCurrentUnit>0){
                //     calcItemCost(itemCurrentUnit, itemUnit);
                // }
            }
        } else {
            setItemQtyUnit('');
        }
    }
    const setterItemCost = (input) => {
        if(input.target.value.length){
            const value = parseFloat(input.target.value);
            if(value>=0){
                setItemCost(value);
            }
        } else {
            setItemCost('');
        }
    }
    const setterItemUnit = (input) => {
        const unitId = parseInt(input.target.value);
        if(unitId>0){
            setItemUnit(unitId);
            if(unitId>0&&itemCurrentUnit>0){
                calcItemCost(itemCurrentUnit, unitId);
            }
        }
    }
    const calcItemCost = (fromUnit, toUnit) => {
        let isCalc = false;
        for(let counter=0;counter<unitConversions.length;counter++){
            const obj = unitConversions[counter];
            if(
                parseInt(fromUnit)==parseInt(obj.from_unit_id)
                &&
                parseInt(toUnit) == parseInt(obj.to_unit_id)
            ){
                let calcCost = parseFloat((parseFloat(defaultItemCost) / parseFloat(obj.unit_conversion))).toFixed(2);
                setItemCost(calcCost);
                setItemQtyUnit(parseFloat(obj.unit_conversion));
                isCalc = true;
                break;
            }
        }
        if(!isCalc){
            setItemCost(defaultItemCost);
        }
    }
    const removeRecipeCompleted = () => {
        Swal.fire({
            icon: 'warning',
            title: 'Confirmación',
            text: '¿Esta seguro de eliminar esta receta?',
            // showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Save',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            // denyButtonText: `Don't save`
        }).then((result)=>{
            if (result.isConfirmed) 
            { 
                execRemoveRecipeCompleted();                
            }
        })        
    }
    const execRemoveRecipeCompleted = async() => {
        setLoadings({
            ...loadings,
            chargingRemoveRecipe: true
        });
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'remove/recipe/complete',{
                token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                // eslint-disable-next-line react/prop-types
                recipeId: generalData.recipeId
            }),
            data =  await resource.data;
            setLoadings({
                ...loadings,
                chargingRemoveRecipe: false
            });
            if(data.sessionOut){
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: 'La sesión ha expirado, será redireccionado'
                }).then(()=>{
                    // eslint-disable-next-line no-undef
                    location.href = process.env.REACT_APP_PATH_APP;
                });
                return false;
            }
            if(!data.status){
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: data.msg
                });
                return;
            }
            // eslint-disable-next-line react/prop-types
            // props.setRecipeItems(data.items);
            setGeneralDataRecipe({
                ...generalData,
                recipes: data.recipes,
                recipeItems: data.items
            });
        } catch(error){
            setLoadings({
                ...loadings,
                chargingRemoveRecipe: false
            });
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Error Interno, favor contacte al administrador"
            });
        }
    }
    const copyFromRecipe = async() => {
        if(fCopyRecipe.value<1){
            Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: 'Debes seleccionar al menos una receta'
            })
            return;
        }
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'recipe/copy',{
                token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                recipeFrom: fCopyRecipe.value,
                // eslint-disable-next-line react/prop-types
                recipeTo: generalData.recipeId
            }),
            data =  await resource.data;
            if(data.sessionOut){
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: 'La sesión ha expirado, será redireccionado'
                }).then(()=>{
                    // eslint-disable-next-line no-undef
                    location.href = process.env.REACT_APP_PATH_APP;
                });
                return false;
            }
            if(!data.status){
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: data.msg
                });
                return;
            }
            // eslint-disable-next-line react/prop-types
            // props.setRecipeItems(data.items);
            setGeneralDataRecipe({
                ...generalData,
                recipes: data.recipes,
                recipeItems: data.items
            });
            setShowCopyRecipeOption(false);
            setFCopyRecipe({value: 0, label: '================'});
        } catch(error){
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Error Interno, favor contacte al administrador"
            });
        }
    }
    const updatePriceOfFinalProduct = async() => {
        setLoadings({
            ...loadings,
            chargingUpdatePrice: true
        });
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'recipe/update/price/final/product',{
                token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                // eslint-disable-next-line react/prop-types
                recipeId: generalData.recipeId
            }),
            data =  await resource.data;
            setLoadings({
                ...loadings,
                chargingUpdatePrice: false
            });
            if(data.sessionOut){
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: 'La sesión ha expirado, será redireccionado'
                }).then(()=>{
                    // eslint-disable-next-line no-undef
                    location.href = process.env.REACT_APP_PATH_APP;
                });
                return false;
            }
            if(!data.status){
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: data.msg
                });
                return;
            }
            // eslint-disable-next-line react/prop-types
            props.setProduct(data.product);
        } catch(error){
            setLoadings({
                ...loadings,
                chargingUpdatePrice: false
            });
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Error Interno, favor contacte al administrador"
            });
        }
    }
    const recipeAddItemValidation = () => {        
        if(subCategoryItem<1){
            return {msg: 'Debes seleccionar al menos una sub-categoría',valid: true};
        }
        if(itemSelected==null||itemSelected.value<1){
            return {msg: 'Debes seleccionar al menos un producto',valid: true};
        }
        if(itemQtyUnit<=0){
            return {msg: 'Favor ingresar la cantidad del material',valid: true};
        }
        if(itemUnit<1){
            return {msg: 'Favor seleccionar al menos una unidad',valid: true};
        }
        if(itemCost<=0){
            return {msg: 'Favor ingresar un costo de material',valid: true};
        }
        return {msg: 'validaciones superadas',valid: false};
    }
    return (
        <div className="container-fluid">
            <div className="row mb-3" style={{marginTop: '15px',marginBottom: '0px'}}>
                <div className="col-8">
                    <h3>Crear listado en receta</h3>
                </div>
                <div className="col-4 d-grid gap-2 d-md-flex justify-content-md-end">
                    <Button 
                        type="button"
                        className="btn-secondary btn-md text-white"
                        styleInput={{marginRight: '10px'}}
                        onClickInput={()=>navigate('/recipes')}
                        contentButton={
                            <>
                                <FontAwesomeIcon icon={faArrowLeft} /> Volver a lista
                            </>
                        }
                    />
                </div>
            </div>
            <div className="row">
                <SelectGroup 
                    onChange={getItemsBySubCategory} 
                    value={subCategoryItem}
                    className="col-4" 
                    inputClassName="form-control"
                    label="Sub-Categoría"
                    inputName="subCategory" 
                    id="subCategory"
                    // eslint-disable-next-line react/prop-types
                    options={generalData.subCategories}
                />
                <div className="col-6">
                    <label style={{marginBottom: '6px'}}>Descripción</label>
                    <Select 
                        options={itemList}                         
                        className="form-control select-styling" 
                        value={itemSelected}
                        defaultValue={itemSelected} 
                        onChange={setItemSelected}
                        ref={reactSelect}
                    />
                </div>
                <div className="col-1" style={{paddingTop: '3.5%'}}>
                    {loadings.chargingProducts?<Spinner animation="border" variant="primary" />:null}
                </div>
            </div>
            <div className="row">            
                <SelectGroup 
                    onChange={setterItemUnit} 
                    value={itemUnit}
                    className="col-3" 
                    inputClassName="form-control"
                    label="Unidad"
                    inputName="unit" 
                    id="unit"
                    // eslint-disable-next-line react/prop-types
                    options={generalData.units}
                />
                <FormGroup
                    id='qty'
                    onChange={setterQty} 
                    value={itemQtyUnit} 
                    className='col-3'
                    inputClassName="form-control"
                    inputType='number' 
                    label="Cantidad"
                    inputName='qty'                    
                />                
                {/* <div className="col-md-2">
                    <label>Costo</label>
                    <input type="number" className="form-control form-control-sm" value={itemCost} onChange={(input)=>{setItemCost(parseFloat(input.target.value))}} />
                </div> */}
                <FormGroup
                    id='itemCost'
                    onChange={setterItemCost} 
                    value={itemCost} 
                    className='col-3'
                    inputClassName="form-control"
                    inputType='number' 
                    label="Costo"
                    inputName='itemCost'
                />                
                <div className="col-3">                    
                    <button type="button" 
                            title="Agregar a la lista"
                            className="btn btn-success btn-md" 
                            style={{marginTop: '30px',marginRight: '10px'}}
                            onClick={addToRecipe}
                            >
                        <FontAwesomeIcon
                            icon={faPlusCircle}                             
                         /> Agregar
                    </button>
                    {/* <button 
                        type="button" 
                        className="btn btn-info btn-sm" 
                        style={{marginTop: '30px'}}
                        // eslint-disable-next-line react/prop-types
                        onClick={()=>props.setShowPopUp(true)}
                        >
                        <FontAwesomeIcon
                            icon={faSearch} 
                            style={{color: '#fff'}}                            
                         />
                    </button>                     */}
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <Button 
                        type="button"
                        className="btn-success btn"
                        // eslint-disable-next-line react/prop-types
                        onClickInput={props.openArticleModel}
                        contentButton={
                            <>
                                <FontAwesomeIcon icon={faPlus} /> Nuevo Material
                            </>
                        }
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12 table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                            <th scope="col">Sub-Categoría</th>                            
                            <th scope="col">Material</th>
                            <th scope="col">Cantidad</th>
                            <th scope="col">Costo</th>
                            <th scope="col">Costo Total</th>
                            <th scope="col">Remover</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                // eslint-disable-next-line react/prop-types
                                (generalData.recipeItems)?
                                    // eslint-disable-next-line react/prop-types
                                    generalData.recipeItems.map((element,idx)=>{
                                        totalCost += parseFloat(element.item_cost);
                                        tCost += parseFloat(element.item_sub_total);
                                        return (
                                            <tr key={idx}>
                                                <td>{element.sub_category_des}</td>                                                
                                                <td>{element.product_code + ' | ' + element.product_des}</td>
                                                <td>{element.qty_unit + ' ' + element.unit_des}</td>
                                                <td>${element.item_cost_format}</td>
                                                <td>${element.item_sub_total_format}</td>
                                                <td><FontAwesomeIcon icon={faTimes} onClick={()=>removeItem(element.id)} style={{cursor: 'pointer'}} /></td>
                                            </tr>
                                        )
                                    })
                                :null
                            }
                            <tr style={{backgroundColor: '#eee',fontWeight: 'bold'}}>
                                <td colSpan={3} style={{textAlign: 'right'}}>COSTO TOTAL:</td>
                                <td>${nFormat(parseFloat(totalCost).toFixed(2))}</td>
                                <td>${nFormat(parseFloat(tCost).toFixed(2))}</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {
                showCopyRecipeOption?
                    <div className="row">
                        <div className="col-md-6">
                            <label style={{marginBottom: '6px'}}>Copiar desde</label>
                            <Select 
                                // eslint-disable-next-line react/prop-types
                                options={generalData.recipesCompleted}                         
                                className="form-control select-styling form-select-sm" 
                                value={fCopyRecipe}
                                defaultValue={fCopyRecipe} 
                                onChange={setFCopyRecipe}
                            />
                        </div>
                        <div className="col-md-6">
                            <br />
                            <Button 
                                type="button"
                                className="btn-primary btn-md"
                                styleInput={{marginRight: '10px'}}
                                onClickInput={copyFromRecipe}
                                contentButton={
                                    <>
                                        <FontAwesomeIcon icon={faCheckCircle} /> Copiar
                                    </>
                                }
                            />
                            <Button 
                                type="button"
                                className="btn-danger btn-md"
                                styleInput={{marginRight: '10px'}}
                                onClickInput={()=>{
                                    setShowCopyRecipeOption(false);
                                    setFCopyRecipe({value: 0, label: '================'});
                                }}
                                contentButton={
                                    <>
                                        <FontAwesomeIcon icon={faTimes} /> Cancelar
                                    </>
                                }
                            />
                        </div>
                    </div>
                : null
            }
            <div className="row" style={{marginTop: '15px'}}>
                <div className="col-12">
                    <p className="text-muted">Nota: Una vez marcada la receta como Completa cada vez que se realice una compra de uno de sus materiales el precio final puede variar.</p>
                    {
                        !deactivateCompleteBtn?
                            loadings.chargingCompleteRecipe?
                                <Button 
                                    type="button"
                                    className="btn-success btn-md"
                                    styleInput={{marginRight: '10px'}}
                                    disabled={true}
                                    onClickInput={()=>{}}
                                    contentButton={
                                        <>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            Cargando...
                                        </>
                                    }
                                />
                            :
                                <Button 
                                    type="button"
                                    className="btn-success btn-md"
                                    styleInput={{marginRight: '10px'}}
                                    onClickInput={complete}
                                    contentButton={
                                        <>
                                            <FontAwesomeIcon icon={faClipboardCheck} /> Marcar receta como completa
                                        </>
                                    }
                                />
                        :
                        // eslint-disable-next-line react/prop-types
                        (quoteItems.indexOf(parseInt(product.id))<0)?
                            loadings.chargingTmpRecipe?
                            <Button 
                                type="button"
                                className="btn-danger btn-md"
                                styleInput={{marginRight: '10px'}}
                                disabled={true}
                                onClickInput={()=>{}}
                                contentButton={
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        Cargando...
                                    </>
                                }
                            />
                            :
                            // <Button 
                            //     type="button"
                            //     className="btn-primary btn-sm"
                            //     styleInput={{marginRight: '10px'}}
                            //     onClickInput={addProductToQuote}
                            //     contentButton={
                            //         <>
                            //             <FontAwesomeIcon icon={faCheckCircle} /> Agregar a cotización
                            //         </>
                            //     }
                            // />
                            // <Button 
                            //     type="button"
                            //     className="btn-primary btn-md"
                            //     styleInput={{marginRight: '10px'}}
                            //     // addProductToQuote
                            //     // eslint-disable-next-line no-undef
                            //     onClickInput={()=>{ window.open(process.env.REACT_APP_PATH_APP + 'quotations?app=135', '_blank') }}
                            //     contentButton={
                            //         <>
                            //             <FontAwesomeIcon icon={faCheckCircle} /> Ir a cotización
                            //         </>
                            //     }
                            // />
                            <Button 
                                type="button"
                                className="btn-danger btn-md"
                                styleInput={{marginRight: '10px'}}
                                // addProductToQuote
                                // eslint-disable-next-line no-undef
                                onClickInput={desMark}
                                contentButton={
                                    <>
                                        <FontAwesomeIcon icon={faCheckCircle} /> Desmarcar para actualización de precio
                                    </>
                                }
                            />
                        :
                        <>
                            <Button 
                                type="button"
                                className="btn-danger btn-sm"
                                styleInput={{marginRight: '10px'}}
                                onClickInput={removeProductFromQuote}
                                contentButton={
                                    <>
                                        <FontAwesomeIcon icon={faCheck} /> Remover este producto de cotización
                                    </>
                                }
                            />
                            <Button 
                                type="button"
                                className="btn-primary btn-sm"
                                styleInput={{marginRight: '10px'}}
                                // eslint-disable-next-line react/prop-types
                                onClickInput={()=>props.setShowQuoteModal(true)}
                                contentButton={
                                    <>
                                        <FontAwesomeIcon icon={faCheck} /> Generar cotización con productos seleccionados
                                    </>
                                }
                            />                            
                        </>
                    }
                    {
                        <>
                            {
                                loadings.chargingUpdatePrice?
                                    <Button 
                                        type="button"
                                        className="btn-warning btn-md"
                                        styleInput={{marginRight: '10px'}}
                                        disabled={true}
                                        onClickInput={()=>{}}
                                        contentButton={
                                            <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                Cargando...
                                            </>
                                        }
                                    />
                                :
                                    <Button 
                                        type="button"
                                        className="btn-warning btn-md"
                                        styleInput={{marginRight: '10px'}}
                                        onClickInput={updatePriceOfFinalProduct}
                                        contentButton={
                                            <>
                                                <FontAwesomeIcon icon={faPenAlt} /> Actualizar precio
                                            </>
                                        }
                                    />
                            }                            
                            <Button 
                                type="button"
                                className="btn-info btn-md text-white"
                                styleInput={{marginRight: '10px'}}
                                onClickInput={()=>setShowCopyRecipeOption(true)}
                                contentButton={
                                    <>
                                        <FontAwesomeIcon icon={faCopy} /> Copiar receta
                                    </>
                                }
                            />
                            {
                                loadings.chargingRemoveRecipe?
                                    <Button 
                                        type="button"
                                        className="btn-danger btn-md"
                                        styleInput={{marginRight: '10px'}}
                                        disabled={true}
                                        onClickInput={()=>{}}
                                        contentButton={
                                            <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                Cargando...
                                            </>
                                        }
                                    />
                                :
                                    <Button 
                                        type="button"
                                        className="btn-danger btn-md text-white"
                                        styleInput={{marginRight: '10px'}}
                                        onClickInput={removeRecipeCompleted}
                                        contentButton={
                                            <>
                                                <FontAwesomeIcon icon={faTimesRectangle} /> Borrar receta
                                            </>
                                        }
                                    />
                            }                            
                        </>
                    }
                </div>
            </div>            
        </div>        
    );
}
export default RecipeItemContentView;