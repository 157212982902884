import React,{useState,useEffect} from "react";
import { Offcanvas,Card, Button } from "react-bootstrap";
import axios from 'axios';
import Swal from 'sweetalert2';
const ClosingBar = (props) => {
    const [bbHistory,setBbHistory] = useState([]),
        [thereAreBbAfter,setThereAreBbAfter] = useState(0),
        [theLastOne,setTheLastOne] = useState(0),
        [limitData,setLimitData] = useState(0);
    useEffect(async()=>{
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API+'get/BusinessBox/history',{token: localStorage.getItem('OSHEN-LOC-STR-TK')}),
                data = resource.data;
            setBbHistory(data.closing);
            setThereAreBbAfter(data.bbHistoryList);
            setTheLastOne(data.theLastOne);
            setLimitData(data.limit);
        } catch (error) {
            Swal.fire({
                icon:'error',
                title: 'Error interno',
                text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
            });
        }
    },[]);
    const giveMeMoreHistory = async() => {
        let giveMeMore = limitData + 5;
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API+'get/BusinessBox/history',{
                token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                theLastOne: theLastOne,
                limitData: giveMeMore
            }),
                data = resource.data;
            setBbHistory(data.closing);
            setThereAreBbAfter(data.bbHistoryList);
            setTheLastOne(data.theLastOne);
            setLimitData(giveMeMore);
        } catch (error) {
            Swal.fire({
                icon:'error',
                title: 'Error interno',
                text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
            });
        }
    }
    const showClosing = async (e) => {
        e.preventDefault();
        const id = e.currentTarget.getAttribute("id");
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API+'get/BusinessBox/history/object',{
                token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                closing: id
            }),
            data = resource.data;
            // eslint-disable-next-line react/prop-types
            props.setBbData(data.closing);
            // eslint-disable-next-line react/prop-types
            props.setBbDataSummary(data.summary);
            // eslint-disable-next-line react/prop-types
            props.handleShow();
            // eslint-disable-next-line react/prop-types
            props.setDenomClosed(data.denomClose);
            // eslint-disable-next-line react/prop-types
            props.setCloseSelected(id);
        } catch (error) {
            Swal.fire({
                icon:'error',
                title: 'Error interno',
                text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
            });
        }
    }
    return (
        <>
        {/* eslint-disable-next-line react/prop-types */}
        <Offcanvas show={props.show} onHide={props.handleShow}>
            <Offcanvas.Header closeButton>
            <Offcanvas.Title>Cierres</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>                    
                {
                    /* eslint-disable-next-line react/prop-types */
                    bbHistory.map((element,idx)=>{
                        return (
                            <Card key={idx} style={{ width: '100%',marginTop: '15px' }}>                        
                                <Card.Body>
                                    <Card.Title>Apertura: {element.from_date}</Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">Cierre#{element.box_id}</Card.Subtitle>
                                    <Card.Text>
                                        Terminal: {element.terminal_des}<br />
                                        Monto de apertura: {element.open_amount}<br />
                                        Cierre por: {element.bp_name}
                                    </Card.Text>
                                    <Card.Link id={element.box_id} href="#" onClick={showClosing}>Ver cierre</Card.Link>
                                </Card.Body>
                            </Card>
                        )
                    })
                }
                {
                    thereAreBbAfter>0?
                        <div className='d-flex justify-content-center' style={{marginTop: '3rem'}}>
                            <Button variant="outline-primary" onClick={giveMeMoreHistory}>Cargar mas cierres...</Button>
                        </div>
                    :null
                }
            </Offcanvas.Body>
        </Offcanvas>
        </>
    )
}
export default ClosingBar;