import React,{useState,useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faFileInvoice, faMoneyBill, faPrint } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import ClosingBar from "../Layouts/closingBar";
import BBox from "./bBox";
import Swal from "sweetalert2";
import axios from "axios";
const BbHistory = () => {
    const [openSideBar,setOpenSideBar] = useState(false),
        [businessBox,setBusinessBox] = useState({}),
        [summary,setSummary] = useState([]),
        [denomClosed,setDenomClosed] = useState([]),
        [closeSelected,setCloseSelected] = useState(null),
        [showDenom,setShowDenom] = useState(false),
        navigate = useNavigate();
    const { id } = useParams();
    useEffect(async()=>{
        if(id!=undefined){
            try{
                // eslint-disable-next-line no-undef
                const resource = await axios.post(process.env.REACT_APP_PATH_API+'get/BusinessBox/history/object',{
                    token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                    closing: id
                }),
                data = resource.data;
                setBusinessBox(data.closing);
                // eslint-disable-next-line react/prop-types
                setSummary(data.summary);
                setDenomClosed(data.denomClose);
            } catch (error) {
                Swal.fire({
                    icon:'error',
                    title: 'Error interno',
                    text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
                });
            }
        }
    },[]);
    const handleSideBarShow = () => {
        if(openSideBar)
            setOpenSideBar(false)
        else
            setOpenSideBar(true)
    }
    const redirectTo = (e) => {
        e.preventDefault();
        const page = e.currentTarget.getAttribute('id');
        let redirectPage = '';
        switch(page){
            case "home":
                redirectPage = '/';
                break;
        }
        navigate(redirectPage);
    }
    const printCuadre = () => {
        const value = (id!==undefined)?id:closeSelected;
        // eslint-disable-next-line no-undef
        window.open(process.env.REACT_APP_PATH_APP+"print/cuadre/"+value,"","width=1024,height=950");
    }
    return (
        <>
        <ClosingBar 
            show={openSideBar}
            handleShow={handleSideBarShow}
            setBbData={setBusinessBox}
            setBbDataSummary={setSummary}
            setCloseSelected={setCloseSelected}
            setDenomClosed={setDenomClosed}
        />
        <div className='container-fluid'>
            <div className="row">
                <div className="col-12">                    
                    <nav className="navbar navbar-expand-lg headers-styling main-nav-side">
                        <div className="container-fluid">
                            <button 
                                type="button" 
                                className="btn btn-primary position-relative navbar-brand" 
                                style={{color: "#fff"}}
                                onClick={handleSideBarShow}
                            >
                                <FontAwesomeIcon icon={faClock} />
                                {
                                    // accountList.length?
                                    //     <span style={{fontSize: 10}} className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                                    //         {accountList.length}<span className="visually-hidden">-</span>
                                    //     </span>
                                    // : null
                                }                                
                            </button>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarText">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">                                    
                                    <a className="nav-link" href="#"></a>
                                </li>
                                <li className="nav-item">
                                <a className="nav-link" href="#"></a>
                                </li>
                                <li className="nav-item">
                                <a className="nav-link" href="#"></a>
                                </li>
                            </ul>
                            <span className="navbar-text">
                                Historial de turnos
                            </span>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
            <div className='d-flex justify-content-center' style={{marginTop: '1rem'}}>
                <div className='col-8'>
                    <button className="btn btn-primary" id="home" onClick={redirectTo} style={{marginLeft: "15%"}}><FontAwesomeIcon icon={faFileInvoice} /> Volver a facturar</button>
                    <button className="btn btn-success" onClick={printCuadre} style={{marginLeft: '5px'}}><FontAwesomeIcon icon={faPrint} /> Imprimir Cuadre</button>
                    <button className="btn btn-warning" onClick={()=>setShowDenom(showDenom?false:true)} style={{marginLeft: '5px'}}><FontAwesomeIcon icon={faMoneyBill} /> {showDenom?'Ocultar Denominaciones':'Ver Denominaciones'}</button>
                </div>
            </div>
            {                
                Object.keys(businessBox).length>0?
                    <BBox 
                        businessBox={businessBox}
                        summary={summary}
                        denomClosed={denomClosed}
                        showDenom={showDenom}
                    />
                :
                <div className='d-flex justify-content-center' style={{marginTop: '1rem'}}>
                    <div className='col-8'>
                        <div className="card">
                            <div className="card-header" style={{backgroundColor: '#d53343',color: '#fff'}}>
                                Historial de cierre
                            </div>
                            <div className="card-body">
                                <div className='container-fluid'>
                                    <div className="row">
                                        <div className="col-12">
                                            <p>No hay un cierre seleccionado, para visualizar las informaciones de un cierre debe abrir el menu lateral.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }            
        </div>
        </>
    )
}
export default BbHistory;